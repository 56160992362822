var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "test_net_speed_modal",
        "cancel-title": _vm.$t("cancel"),
        "ok-title": _vm.$t("ok"),
        title: _vm.$t("start_test_speed"),
        effect: "fade",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: _vm.submit,
      },
    },
    [
      _c("div", [
        _c("p", [
          _vm._v(
            "Please make sure that no other applications are using your Internet connection!"
          ),
        ]),
        _c("p", [
          _vm._v(
            "* Don\\'t close this windows or go back to home screen while testing."
          ),
        ]),
        _c("p", [
          _vm._v(
            "* It\\'s better to leave only this window open because the test will take up some resources of your device."
          ),
        ]),
        _c("p", [
          _vm._v(
            "* The network\\'s speed requirement is at least 4M upload and 4M download"
          ),
        ]),
      ]),
      _c("hr"),
      _c(
        "div",
        {
          staticClass: "container",
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-xs-3",
                },
                [_vm._v(_vm._s(_vm.$t("download") + " "))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [_vm._v(_vm._s(" " + _vm.getSpeedText(5)))]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-xs-3",
                },
                [_vm._v(_vm._s(_vm.$t("upload") + " "))]
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-9",
                },
                [_vm._v(_vm._s(" " + _vm.getSpeedText(10)))]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-xs-12",
                },
                [
                  _vm._v(_vm._s(_vm.$t("select_type_txt"))),
                  _c(
                    "span",
                    {
                      staticClass: "text-danger",
                    },
                    [_vm._v("*")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("b-form-select", {
        attrs: {
          options: _vm.types,
        },
      }),
      _c("b-button", {
        on: {
          click: _vm.startTest,
        },
      }),
      _c("div", [_vm._v(_vm._s(_vm.$t("error_on_download")))]),
      _c("div", [_vm._v(_vm._s(_vm.$t("error_on_upload")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }