var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-form-group",
    {
      attrs: {
        horizontal: "",
        "label-cols": 6,
        breakpoint: "sm",
        label: _vm.$t("paypal_account"),
        "label-for": "txtPaypalEmail",
      },
    },
    [
      _c("b-form-input", {
        attrs: {
          id: "txtPaypalEmail",
          name: "paypalEmail",
          placeholder: _vm.$t("please_input") + _vm.$t("paypal_account"),
        },
        model: {
          value: _vm.data.paypalEmail,
          callback: function ($$v) {
            _vm.$set(_vm.data, "paypalEmail", $$v)
          },
          expression: "data.paypalEmail",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }