var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "row",
    },
    [
      _c(
        "div",
        {
          staticClass: "col-md-6",
        },
        [_c("profile-data")],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-md-6",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-md-12",
            },
            [_c("vip-preference")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-12",
            },
            [_c("payment")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-12",
            },
            [_c("certificates")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-12",
            },
            [_c("device")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }