var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "remove_device_modal",
        "cancel-title": "Cancle",
        "ok-title": "OK",
        title: _vm.$t("remove_device"),
        effect: "fade",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          return _vm.submit()
        },
      },
    },
    [
      _c("span", [
        _vm._v(
          _vm._s(_vm.$t("remove_confirm_mes")) +
            '  "' +
            _vm._s(_vm.device.brand) +
            " " +
            _vm._s(_vm.device.model) +
            '" ?'
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }