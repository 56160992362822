var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols": _vm.labelCols,
        label: `${_vm.need ? "*" : ""} ${_vm.label}`,
      },
    },
    [
      _vm.inputType === "text" || _vm.inputType === "password"
        ? _c("b-form-input", {
            class: _vm.fileClass,
            attrs: {
              type: _vm.inputType,
              placeholder: _vm.placeholder,
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.inputType === "file"
        ? _c("b-form-file", {
            attrs: {
              accept: _vm.accept,
              placeholder: _vm.placeholder,
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }