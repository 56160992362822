var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.value && _vm.value !== "null" && _vm.value !== null
    ? _c(
        "li",
        {
          staticClass: "list-group-item",
        },
        [
          _c(
            "span",
            {
              staticClass: "list-title-box",
            },
            [_vm._v(_vm._s(_vm.$t(_vm.title)))]
          ),
          _c("span", {
            staticClass: "float-right list-content-box text-secondary",
            domProps: {
              innerHTML: _vm._s(_vm.value),
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }