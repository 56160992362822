var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "h3",
      {
        staticClass: "text-center",
      },
      [
        _vm.queried
          ? _c("span", [
              _vm._v(_vm._s(_vm.queryMonth + " Average Rating: " + _vm.score)),
            ])
          : _c("span", [
              _vm._v(
                _vm._s("This Month's Average Rating:") +
                  _vm._s(
                    _vm.getScoreAvg(this.consultantEvaluationScore.score.avg)
                  )
              ),
            ]),
      ]
    ),
    _c(
      "div",
      {
        attrs: {
          align: "center",
        },
      },
      [
        _c(
          "b-button",
          {
            attrs: {
              type: "button",
              variant: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.otherMonth()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("check_other_month")))]
        ),
      ],
      1
    ),
    _vm.show_query_month
      ? _c(
          "div",
          {
            staticClass: "query-box",
            attrs: {
              align: "center",
            },
          },
          [
            _c("datepicker", {
              attrs: {
                minimumView: "month",
                maximumView: "month",
                "bootstrap-styling": true,
                format: "yyyy-MM",
              },
              model: {
                value: _vm.queryOtherMonth,
                callback: function ($$v) {
                  _vm.queryOtherMonth = $$v
                },
                expression: "queryOtherMonth",
              },
            }),
            _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "button",
                      variant: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.queryOtherMonthScore(_vm.queryOtherMonth)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("query")))]
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "button",
                      variant: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeOtherMonth()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("close")))]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }