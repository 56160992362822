var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.value && _vm.value !== "null" && _vm.value !== null
    ? _c(
        "li",
        {
          staticClass: "list-group-item",
        },
        [
          _c("b", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
          _c(
            "a",
            {
              staticClass: "float-right",
              attrs: {
                target: "_blank",
                href: _vm.value,
              },
            },
            [_vm._v(_vm._s(_vm.$t("view")))]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }