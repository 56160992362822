var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-head",
        },
        [
          _c(
            "h4",
            {
              staticClass: "wuwow-card-title",
            },
            [_vm._v(_vm._s(_vm.$t("student_preference")))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wuwow-card-body",
        },
        [
          _c(
            "div",
            {
              staticClass: "preference d-flex justify-content-around mt-1",
            },
            [
              _c(
                "div",
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: "thumbs-up",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.consultantEvaluationScore.like_amount)
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: "thumbs-down",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.consultantEvaluationScore.dislike_amount)
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }